@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200&display=swap');

.App {
  text-align: center;
}

.App-header {
  background: linear-gradient(-45deg, #a852ee, #f85398, #23d591, #22bcef, #86E5FF, #ffffff);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-size: 50px;
  color: white;
  font-family: 'Dosis', sans-serif;
}

h2, h3 {
  font-size: 35px;
  color: white;
  font-family: 'Dosis', sans-serif;
}

button {
  background-color: transparent;
  text-align: center;
  border-radius: 5px;
  border-color: white;
  font-family: 'Dosis', sans-serif;
  color: white;
}

button:hover {
  text-decoration: none;
}

.btn-link {
  background-color: transparent;
  color: white;
  border-radius: 5px;
  border-color: white;
  font-family: 'Dosis', sans-serif;
  text-decoration: none;
}

.coffee {
  color: white;
  padding-right: 50px;
  padding-left: 50px;
}

.btn-contact {
  background-color: transparent;
  left: -50px;
  color: white;
  border-radius: 5px;
  border-color: white;
  font-family: 'Dosis', sans-serif;
  text-decoration: none;
}

.fade-in {
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.fade-in {
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
